import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "styles/components/button_group.module.css"

const ButtonGroup = ({
  children,
}) => (
  <div className={ classNames(
    "button-group-component",
    styles.component,
  ) }>
    { React.Children.map(children, ((child) => (
      React.cloneElement(child, {
        className: child.props.className + ` ${ styles.button }`,
      })
    ))) }
  </div>
)

ButtonGroup.propTypes = {
  children: PropTypes.node,
}

export default ButtonGroup
