import React from "react"
import dynamic from "next/dynamic"
import PropTypes from "prop-types"
import { List } from "immutable"
import classNames from "classnames"
import ImmutablePropTypes from "react-immutable-proptypes"
import { getTreeFromFlatData } from "highline/utils/resource_tree"
import { EllipsisIcon } from "highline/components/icons"
import Tooltip from "highline/components/tooltip"

import styles from "highline/styles/components/admin/resource_tree.module.css"

const ReactSortableTree = dynamic(import("react-sortable-tree"), {
  ssr: false,
})

const hasSameParent = ({ node, nextParent }) => {
  if (!node.parentId && !nextParent) // root category
    return true

  if (nextParent && (node.parentId === nextParent.id)) // same parent
    return true

  return false
}

class ResourceTree extends React.PureComponent {
  static propTypes = {
    currentPopUpId: PropTypes.number,
    onChange: PropTypes.func,
    onClickMoreOptions: PropTypes.func,
    onMoveNode: PropTypes.func,
    onVisibilityToggled: PropTypes.func,
    titleField: PropTypes.string,
    tooltipMenuComponent: PropTypes.element,
    treeData: ImmutablePropTypes.list,
  }

  static defaultProps = {
    currentPopUpId: 0,
    onChange: () => {},
    onClickMoreOptions: () => {},
    onMoveNode: () => {},
    onVisibilityToggled: () => {},
    titleField: "name",
    treeData: new List(),
  }

  render() {
    const {
      currentPopUpId,
      treeData,
      onChange,
      onClickMoreOptions,
      onVisibilityToggled,
      onMoveNode,
      titleField,
      tooltipMenuComponent,
    } = this.props

    const formattedData = getTreeFromFlatData({
      flatData: treeData.toJS(),
      getKey: (node) => node.id,
      getParentKey: (node) => node.parentId,
      rootKey: null,
    })

    return (
      <div className={ classNames(
        "component",
        "sortable-tree-component",
        styles.component,
      ) }>
        <ReactSortableTree
          treeData={ formattedData }
          onChange={ onChange }
          onMoveNode={ onMoveNode }
          onVisibilityToggle={ onVisibilityToggled }
          canDrop={ hasSameParent }
          getNodeKey={ ({ node }) => node.id }
          generateNodeProps={ ({ node }) => ({
            title: (
              <div className={ styles.row }>
                <div className={ classNames(
                  styles.dot,
                  node.isActive ? styles.active : null,
                ) } />

                <div className={ styles.content }>
                  { node[titleField] }
                </div>

                <Tooltip
                  isOpen={ currentPopUpId === node.id }
                  onClose={ () => { } }
                  layout="admin"
                  modifiers= { {
                    offset: {
                      offset: "0 24px",
                    },
                  } }
                  target={
                    <button
                      className={ styles.moreOptionsButton }
                      aria-label="Open dialog with more options"
                      onClick={ (evt) => {
                        evt.preventDefault()
                        evt.stopPropagation()
                        onClickMoreOptions(node)
                      } }
                    >
                      <EllipsisIcon />
                    </button>
                  }
                >
                  {
                    tooltipMenuComponent &&
                    React.cloneElement(tooltipMenuComponent, { node })
                  }
                </Tooltip>
              </div>
            ),
          }) }
        />
      </div>
    )
  }
}

export default ResourceTree
