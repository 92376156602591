import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "highline/styles/components/admin/admin_edit_menu.module.css"

const AdminEditMenu = ({
  children,
}) => (
  <div className={ classNames(
    "component",
    "admin-edit-menu-component",
    styles.component,
  ) }>
    { children }
  </div>
)

AdminEditMenu.propTypes = {
  children: PropTypes.node,
}

export default AdminEditMenu
