import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import styles from "highline/styles/components/admin/admin_edit_menu_item.module.css"

const AdminEditMenuItem = ({
  icon,
  label,
  nodeId,
  onClick,
}) => (
  <button className={ classNames(
    "component",
    "admin-edit-menu-item-component",
    styles.component,
  ) }
  onClick={ (evt) => {
    evt.preventDefault()
    evt.stopPropagation()
    onClick(nodeId)
  } }
  >
    { icon &&
      <div>{ icon }</div>
    }
    { label &&
      <span>{ label }</span>
    }
  </button>
)

AdminEditMenuItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  nodeId: PropTypes.number,
  onClick: PropTypes.func,
}

export default AdminEditMenuItem
