import React from "react"
import PropTypes from "prop-types"
import Modal from "highline/components/modal"
import ButtonGroup from "highline/components/button_group"
import classNames from "classnames"

import styles from "styles/components/action_dialog.module.css"

const ActionDialog = ({
  children,
  error,
  onClose,
  onSubmit,
  title,
  layout,
  isForm,
  header,
}) => {
  const content = []
  let buttons

  React.Children.forEach(children, (child) => {
    if (child) {
      if (child.type == ButtonGroup || child.type.displayName === "ButtonGroup")
        buttons = child
      else
        content.push(child)
    }
  })

  return (
    <div className={ classNames(
      "component",
      "action-dialog-component",
      styles[layout],
    )} >
      <Modal layout={ layout } onRequestClose={ onClose }>
        { !isForm ?
          <div>
            <div className={ styles.content }>
              <div className={ styles.title }>
                { title }
              </div>
              { header &&
                <div className={ styles.header }>{ header }</div>
              }
              { content }
              { error &&
                <div className={ styles.errorMessage }>{ error }</div>
              }
            </div>

            { buttons &&
              <div className={ styles.buttons }>
                { buttons }
              </div>
            }
          </div>
          :
          <div>
            <div className={ styles.formContent }>
              <div className={ styles.title }>
                { title }
              </div>
              { header &&
                <div className={ styles.header }>{ header }</div>
              }
              <form onSubmit={ onSubmit }>
                <div className={ styles.content }>
                  { content }
                </div>
                { error &&
                  <div className={ styles.errorMessage }>{ error }</div>
                }
                { buttons &&
                  <div className={ styles.buttons }>
                    { buttons }
                  </div>
                }
              </form>
            </div>

          </div>
        }
      </Modal>
    </div>
  )
}

ActionDialog.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string,
  header: PropTypes.node,
  isForm: PropTypes.bool,
  layout: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
}

ActionDialog.defaultProps = {
  header: null,
  isForm: false,
  layout: "roundedFlexible",
  onSubmit: () => {},
}

export default ActionDialog
